/* home.css */

.about-info {
  padding-right: 10%;
  max-width: 800px;
  font-size: 1.25em;
  text-align: right;
  color: #1800FF;
}

.about-info-h1 {
  font-size: 12em;
  text-align: left;
  font-weight: 600;
  position: flex;
}

.about-info h2 {
  font-size: 1.5em;
  margin-bottom: 30px;
}

.about-info p {
  font-size: 1em;
  line-height: 1.2;
  font-weight: 640;
  text-align: left;
  margin-bottom: 10%;
  margin-top: 10%;
}

.mobile-hor-center{
  text-align: end;
  padding-right: 5%;
}

.cta-button {
  text-decoration: none;
  font-size: 1.25em;
  padding: 10px 30px;
  color: #fff;
  font-family: "Gill Sans";
  background: #000aff;
  border-radius: 21px;
  transition: background 0.3s;
}


.multi-content-section {
  padding-top: 10%;
  display: flex;
  justify-content: space-around;
  margin: 75px 0;
}

.image-container {
  max-width: 2000px;
  text-align: center;
  padding: 3%;
}

.image-container img {
  width: 100%;
  max-width: 1000px;
  max-height: 400px;
  border-radius: 10px;
}

.image-container .title {
  margin-top: 10px;
  font-size: 1em;
  text-align: center;
  font-weight: 600;
  color: #1800FF;
}

.media-logo-section {
  margin: 5px 0;
  align-items: center;
}


.media-logo-section .media-title{
  /* padding-top: 10%; */
  text-align: center;
  font-size: 1.25em;
  font-weight: 700;
  color: #1800ff;
}
.media-logo-section .media-title-mobile{
  display: none;
}

.media-logo-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.media-logo-container {
  display: flex;
  justify-content: center;
}

.media-logo-container img {
  max-width: 80%;
  padding: 10px 50px;
}

.media-text-section {
  display: flex;
  justify-content: space-around;
  margin: 50px 0;
}
.media-logos{
  display:inline-block;
  padding-top: 50px;
  width: 70%;
}

.media-text-container {
  text-align: center;
}

.media-text-container h1 {
  font-size: 3em;
  color: #1800ff;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
}

.media-text-container p {
  font-size: 1.25em;
  line-height: 1.6;
  font-weight: 600;
  text-align: center;
  color: #1800ff;
}


/* Mobile Styles (up to 767px) */
@media only screen and (max-width: 767px) {
  .about-info-h1 {
    font-size: 5em;
    line-height: 0.8em;
    font-weight:400;
    text-align: left;
  }

  .about-info {
    padding-left: 0%;
    padding-right: 0%;
    font-size: 0.9em;
    text-align: left;
  }

  .mobile-hor-center{
    text-align: start;
    padding-right: 5%;
  }
  
  .cta-button {
    text-decoration: none;
    font-size: 1em;
    padding: 5px 20px;
    color: #fff;
    font-family: "Gill Sans";
    background: #000aff;
    border-radius: 21px;
    transition: background 0.3s;
  }

  .multi-content-section {
    flex-direction: column;
    padding-top: 10px;
  }

  .image-container {
    max-width: 100%;
    padding-bottom: 10px;
  }

  .image-container {
    max-width: 800px;
  }
  .image-container img {
    width: 100%;
    height: auto;
    max-width: 800px;
    border-radius: 5px;
  }
  /* .image-container img{
    max-width: 150%;
  } */

  .image-container .title {
    margin-top: 5px;
    font-size: 0.7em;
    text-align: center;
  }

  .media-logo-section .media-title{
    display: none;
  }
  .media-logo-section .media-title-mobile{
    display:block;
    align-items: center;
    /* padding-top: 10%; */
    text-align: center;
    font-size: 1.25em;
    font-weight: 800;
    line-height: 130%;
    color: #1800ff;
  }

  .media-logo-container {
    margin-bottom: 15px;
  }
  .media-logo-row{
    margin-bottom: 0%;
  }
  .media-logo-row img{
    max-width: 70px;
  }
  .media-logo-container img {
    padding:0px 15px;
  }
  .media-logos{
    display:inline-block;
    padding-top: 45px;
  }

  .media-text-section {
    flex-direction: column;
    margin: 10px 0;
  }

  .media-text-container h1 {
    font-size: 1em;
  }

  .media-text-container p {
    font-size: 0.7em;
  }
}

/* Tablet Styles (768px to 1024px) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .about-info-h1 {
    font-size: 5em;
    text-align: center;
  }

  .about-info {
    padding-left: 20%;
    padding-right: 20%;
    font-size: 1.1em;
  }

  .multi-content-section {
    padding-top: 200px;
    flex-direction: row;
    justify-content: space-between;
  }

  .image-container {
    max-width: 45%;
  }

  .media-logo-section .media-title {
    font-size: 1.75em;
  }

  .media-logo-row {
    flex-direction: row;
  }

  .media-text-container h1 {
    font-size: 2em;
  }

  .media-text-container p {
    font-size: 1em;
  }
}