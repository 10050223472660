/* Page-specific styles */
.home, .about, .contact, .work {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.home h1, .about h1, .contact h1, .work h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.home p, .about p, .contact p, .work p {
  font-size: 1.2em;
  line-height: 1.6em;
}

.main-body {
  margin: 5% 5%
}

.main-body h1, p, h2, h3 {
  font-family: "Calibri";
  color: #1800FF;
}

@media (max-width: 768px) {
  .main-body {
    margin: 0%;
  }
}