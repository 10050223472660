/* Basic reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  
body {
    font-family: Arial, sans-serif;
}

/* Header Styling */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: white;
}

.logo img {
    max-width: 80px; /* Adjust according to logo size */
}

.logo {
    padding-left: 10px;
    padding-top: 15px;
}

.menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 16px;
    padding-right: 20px;
    cursor: pointer;
}

.bar {
    width: 26px;
    height: 4px;
    background-color: #1800ff; /* Menu icon color */
    border-radius: 3px;
}

/* Hidden mobile nav menu by default */
.mobile-nav {
    position: absolute;
    top: 60px;
    right: 0;
    height: 100vh;
    width: 60%;
    background-color: white;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
}

.mobile-nav ul {
    list-style-type: none;
    padding: 50px 20px;
    text-align: left;
}

.mobile-nav ul li {
    margin: 30px 0;
    text-align: center;
}

.cta-button-h {
    text-decoration: none;
    color: #737373;
    padding: 10px 20px;
    font-weight: bold;
}

.active {
    color: #2500FF;
}

.contact-btn {
    text-decoration: none;
    color: white;
    background-color: #1800ff;
    padding: 7px 30px;
    border-radius: 30px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
}

/* Show mobile menu when active */
.mobile-nav.active {
    transform: translateX(0%);
}
