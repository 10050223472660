body {
    margin: 0;
    padding: 0;
    background-color: white;
}

.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.pc-main {
    text-align: center;
}

.contact-title-container {
    padding: 3% 7%;
}

.contact-title {
    font-size: 100px;
    color: blue;
    margin-bottom: 50px;
    text-align: left;
}

.contact-form-container {
    display: flex;
    padding: 3% 7%;
    flex-direction: column;
    align-items: center;
}

.contact-form-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%; /* Make it take the full width of its container */
}

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 70px;
    max-width: 60%;
    width: 100%;
    margin-bottom: 5%; /* Add some margin between form fields and the button */
}

.contact-field {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.contact-label {
    font-size: 18px;
    color: blue;
    text-align: left;
    margin-bottom: 10px;
}

.contact-input {
    border: none;
    border-bottom: 1px solid #000;
    padding: 10px;
    font-size: 16px;
    outline: none;
}

.contact-submit {
    display: block;
    padding: 0.5% 2%;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 18px;
    cursor: pointer;
    align-self: center; /* Center the button horizontally */
}

.alter-contact {
    padding-bottom: 70px;
}

/* Style the "Alternatively contact us via" label */
.alter-title {
    color: blue; /* Set the label color to blue */
    font-weight: bold;
    font-size: 32px;
    text-align: left;
    padding-bottom: 15%;
}

/* Style the following paragraphs inside the alternative contact section */
.alter-contact p {
    color: grey; /* Set the paragraph text color to grey */
    text-align: left; /* Left align the text */
    font-size: 20px;
    margin: 15px 0; /* Add some space between paragraphs */
}

.contact-submit-mobile {
    display: none;
}




/* Mobile Responsiveness */
@media (max-width: 768px) {
    /* hide PC button */
    .contact-submit {
        display: none;
    }

    /* Stack contact form details vertically */
    .contact-form-details {
        flex-direction: column;
        align-items: center;
    }

    /* Ensure contact-form details take full width and height */
    .contact-form-container {
        padding: 5% 3%;
    }

    .contact-form {
        width: 90%; /* Adjust width */
        max-width: 100%;
        margin-bottom: 20px; /* Add space below the form */
        gap: 30px; /* Reduce the gap between input fields */
    }

    .contact-field {
        width: 100%; /* Ensure fields take full width */
    }

    .contact-label {
        font-size: 16px; /* Smaller label text */
    }

    .contact-input {
        font-size: 14px; /* Smaller input text */
    }

    .contact-submit-mobile {
        display: block;
        font-size: 16px; /* Smaller button text */
        padding: 1% 4%; /* Adjust button padding */
        margin-top: 20px; /* Add space between button and last input field */
        background-color: blue;
        color: white;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        align-self: center; /* Center the button horizontally */
    }

    .alter-contact {
        padding-top: 30px; /* Space above .alter-contact */
        margin-top: 20px; /* Space between button and .alter-contact */
    }

    .contact-title {
        font-size: 50px; /* Reduce title size */
    }

    .alter-title {
        font-size: 24px; /* Reduce alternative contact title size */
    }

    .alter-contact p {
        font-size: 16px; /* Smaller paragraph text */
        margin: 10px 0; /* Space between paragraphs */
    }
}